import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { ActivePage } from "../steps/Steps";
import { ElectronicTradeDocumentFormatType, ElectronicTradeDocumentType } from "./bookingSlice";
import { Template } from "../utilities/TemplateHandler";

export enum OwnOrGenerate {
  OWN_INVOICE = 'OWN_INVOICE',
  GENERATE = 'GENERATE'
}

export enum AttachOrUpload {
  ATTACH_TO_SHIPMENT = 'ATTACH_TO_SHIPMENT',
  UPLOAD = 'UPLOAD'
}

export enum AttachOrSubmit {
  DOWNLOAD_AND_ATTACH = 'DOWNLOAD_AND_ATTACH',
  SUBMIT = 'SUBMIT'
}

export interface Invoice {
  ownOrGenerate: OwnOrGenerate,
  attachOrUpload: AttachOrUpload,
  attachOrSubmit: AttachOrSubmit,
  number: string,
  date: number,
  name: string,
  position: string
}

export interface AppData {
  showHomePageErrors: boolean;
  homePageErrors: boolean;
  homePagePieceErrors: boolean;
  homePageStatus: PageStatus;
  quotePageStatus: PageStatus;
  detailsPageStatus: PageStatus;
  confirmPageStatus: PageStatus;
  activePage: ActivePage;
  showDetailsPageErrors: boolean;
  detailsPageHasErrors: boolean;
  detailsPageCommoditiesHasErrors: boolean;
  sectionToEdit: string;
  countries: { value: string, title: string, currencyCode: string }[];
  originCounties: { title: string, value: string }[];
  destinationCounties: { title: string, value: string }[];
  originPostalCodeRegex: string;
  destinationPostalCodeRegex: string;
  shipperPostalCodeRegex: string;
  shipperCounties: { title: string, value: string }[];
  brokerPostalCodeRegex: string;
  brokerCounties: { title: string, value: string }[];
  commodityWeightValueError: boolean;
  commodityPartialCompleteError: boolean;
  collectionTimesError: boolean;
  unsupportedCurrency: boolean;
  invoice: Invoice;
  saveCollectionAddress: boolean;
  saveShipperAddress: boolean;
  saveConsigneeAddress: boolean;
  saveBrokerAddress: boolean;
  isFreightChargeOverwritten: boolean;
  isInvoiceNumberOverwritten: boolean;
  displayInsurance: boolean;
  customRules: { rule: string, value: any }[];
  preferredCurrencyChanged: boolean;
  dropInSelected: boolean;
  currencies: any[];
  activeTemplate: Template | null;
  uploadedDocuments: {
    type: ElectronicTradeDocumentType,
    contentType: ElectronicTradeDocumentFormatType,
    documentContent: string,
    documentDate: string,
    reference: string
  }[];
  quoteSort: number;
  isImperialMetricOverwritten: boolean;
  isReQuoting: boolean;
  apiConfig: any;
  previewMode: boolean;
  temporaryLogo: {
    name: string;
    content: any;
    url: string;
  };
  temporaryBanner: {
    name: string;
    content: any;
    url: string;
  };
  temporaryColors: {
    primary: string,
    secondary: string,
    tertiary: string,
    success: string,
    error: string,
    warning: string,
    text: string,
    textMid: string,
    textLight: string,
    disabledTodo: string,
    background: string,
    backgroundDark: string,
    backgroundHover: string
  } | null;
  existingTheme: {
    parentPartyId: string;
    colourScheme: any;
    logoUuid: string | null;
    bannerImageUuid: string | null;
    labelPreferences: any;
    logoUrl: string;
    bannerUrl: string;
  };
  wlLogoImage: any;
  wlBannerImage: any;
  customColors: any;
  isWl: boolean;
  marketingImage: string;
}

export enum PageStatus {
  TODO = 'TODO',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE'
}

export interface ApiConfig {
  headers: any;
}

const initialState: AppData = {
  showHomePageErrors: false,
  homePageErrors: false,
  homePagePieceErrors: false,
  homePageStatus: PageStatus.TODO,
  quotePageStatus: PageStatus.TODO,
  detailsPageStatus: PageStatus.TODO,
  confirmPageStatus: PageStatus.TODO,
  activePage: ActivePage.START,
  showDetailsPageErrors: false,
  detailsPageHasErrors: false,
  detailsPageCommoditiesHasErrors: false,
  sectionToEdit: '',
  countries: [],
  originCounties: [],
  destinationCounties: [],
  originPostalCodeRegex: '',
  destinationPostalCodeRegex: '',
  shipperPostalCodeRegex: '',
  shipperCounties: [],
  brokerPostalCodeRegex: '',
  brokerCounties: [],
  commodityWeightValueError: false,
  commodityPartialCompleteError: false,
  collectionTimesError: false,
  unsupportedCurrency: false,
  invoice: {
    ownOrGenerate: OwnOrGenerate.OWN_INVOICE,
    attachOrUpload: AttachOrUpload.ATTACH_TO_SHIPMENT,
    attachOrSubmit: AttachOrSubmit.DOWNLOAD_AND_ATTACH,
    number: '',
    date: 0,
    name: '',
    position: ''
  },
  saveCollectionAddress: false,
  saveShipperAddress: false,
  saveConsigneeAddress: false,
  saveBrokerAddress: false,
  isFreightChargeOverwritten: false,
  isInvoiceNumberOverwritten: false,
  displayInsurance: false,
  customRules: [],
  preferredCurrencyChanged: false,
  dropInSelected: false,
  currencies: [],
  activeTemplate: null,
  uploadedDocuments: [],
  quoteSort: 0,
  isImperialMetricOverwritten: false,
  isReQuoting: false,
  apiConfig: {
    'headers': {
      'Content-Type': '',
      'authorization': '',
      'IdToken': '',
      'Master-Token': ''
    }
  },
  previewMode: false,
  temporaryLogo: {
    name: '',
    content: null,
    url: ''
  },
  temporaryBanner: {
    name: '',
    content: null,
    url: ''
  },
  temporaryColors: null,
  existingTheme: {
    parentPartyId: '',
    colourScheme: null,
    logoUuid: null,
    bannerImageUuid: null,
    labelPreferences: null,
    logoUrl: '',
    bannerUrl: ''
  },
  wlLogoImage: null,
  wlBannerImage: null,
  customColors: null,
  isWl: false,
  marketingImage: ''
}

export const appDataSlice = createSlice({
  name: 'appData',
  initialState,
  reducers: {
    setShowHomePageErrors(state, action) {
      state.showHomePageErrors = action.payload;
    },
    setHomePageErrors(state, action) {
      state.homePageErrors = action.payload;
    },
    setHomePagePieceErrors(state, action) {
      state.homePagePieceErrors = action.payload;
    },
    setActivePage(state, action) {
      state.activePage = action.payload;
    },
    setQuotePageStatus(state, action) {
      state.quotePageStatus = action.payload;
    },
    setDetailsPageStatus(state, action) {
      state.detailsPageStatus = action.payload;
    },
    setConfirmPageStatus(state, action) {
      state.confirmPageStatus = action.payload;
    },
    setStepsAfterQuote(state) {
      state.homePageStatus = PageStatus.COMPLETE;
      state.detailsPageStatus = PageStatus.TODO;
      state.confirmPageStatus = PageStatus.TODO;
    },
    setShowDetailsPageErrors(state, action) {
      state.showDetailsPageErrors = action.payload;
    },
    setDetailsPageErrors(state, action) {
      state.detailsPageHasErrors = action.payload;
    },
    setDetailsPageCommoditiesErrors(state, action) {
      state.detailsPageCommoditiesHasErrors = action.payload;
    },
    setSectionToEdit(state, action) {
      state.sectionToEdit = action.payload;
    },
    setCountries(state, action) {
      state.countries = action.payload;
    },
    setOriginCounties(state, action) {
      state.originCounties = action.payload;
    },
    setDestinationCounties(state, action) {
      state.destinationCounties = action.payload;
    },
    updateField(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        [field]: value
      }
    },
    setCommodityWeightValueError(state, action) {
      state.commodityWeightValueError = action.payload;
    },
    setCommodityPartialCompleteError(state, action) {
      state.commodityPartialCompleteError = action.payload;
    },
    setCollectionTimesError(state, action) {
      state.collectionTimesError = action.payload;
    },
    updateInvoiceDetail(state, action) {
      const field = action.payload.field;
      const value = action.payload.value;
      return {
        ...state,
        invoice: {
          ...state.invoice,
          [field]: value
        }
      }
    },
    resetStateWithConditions(state) {
      return {
        ...initialState,
        countries: state.countries,
        customRules: state.customRules,
        apiConfig: state.apiConfig,
        previewMode: state.previewMode,
        temporaryLogo: state.temporaryLogo,
        temporaryBanner: state.temporaryBanner,
        existingTheme: state.existingTheme,
        temporaryColors: state.temporaryColors,
        wlLogoImage: state.wlLogoImage,
        wlBannerImage: state.wlBannerImage,
        customColors: state.customColors
      };
    },
    setCollectionSaveAddressCheckbox(state) {
      state.saveCollectionAddress = !state.saveCollectionAddress;
    },
    setShipperSaveAddressCheckbox(state) {
      state.saveShipperAddress = !state.saveShipperAddress;
    },
    setConsigneeSaveAddressCheckbox(state) {
      state.saveConsigneeAddress = !state.saveConsigneeAddress;
    },
    setBrokerSaveAddressCheckbox(state) {
      state.saveBrokerAddress = !state.saveBrokerAddress;
    },
    putCustomRule(state, action) {
      if (state.customRules.find(customRule => customRule.rule === action.payload.rule)) {
        this.deleteCustomRule(state, action);
      }
      state.customRules.push(action.payload);
    },
    setCustomRules(state, action) {
      state.customRules = action.payload;
    },
    deleteCustomRule(state, action) {
      state.customRules.slice(action.payload);
    },
    setDropInSelected(state, action) {
      state.dropInSelected = action.payload;
    },
    addDocument(state, action) {
      state.uploadedDocuments.push(action.payload)
    },
    deleteDocument(state, action) {
      const uploadedDocuments = [...state.uploadedDocuments]
      uploadedDocuments.splice(action.payload, 1);
      state.uploadedDocuments = uploadedDocuments;
    },
    setQuoteSort(state, action) {
      state.quoteSort = action.payload;
    },
    setIsImperialMetricOverwritten(state, action) {
      state.isImperialMetricOverwritten = action.payload;
    },
    setIsReQuoting(state, action) {
      state.isReQuoting = action.payload;
    },
    setApiConfig(state, action) {
      state.apiConfig = action.payload;
    },
    setPreviewMode(state, action) {
      state.previewMode = action.payload;
    },
    setTemporaryLogo(state, action) {
      state.temporaryLogo = action.payload;
    },
    setTemporaryBanner(state, action) {
      state.temporaryBanner = action.payload;
    },
    setTemporaryLogoUrl(state, action) {
      state.temporaryLogo = {
        ...state.temporaryLogo,
        url: action.payload
      };
    },
    setTemporaryBannerUrl(state, action) {
      state.temporaryBanner = {
        ...state.temporaryBanner,
        url: action.payload
      };
    },
    setTemporaryColors(state, action) {
      state.temporaryColors = action.payload;
    },
    setWlLogoImage(state, action) {
      state.wlLogoImage = action.payload;
    },
    setWlBannerImage(state, action) {
      state.wlBannerImage= action.payload;
    },
    setExistingTheme(state, action) {
      state.existingTheme = action.payload;
    },
    setCustomColors(state, action) {
      state.customColors = action.payload;
    },
    setActiveTemplate(state, action) {
      state.activeTemplate = action.payload;
    },
    setIsWhiteLabel(state, action) {
      state.isWl = action.payload;
    },
    setApiConfigAuthorizationHeader(state, action) {
      state.apiConfig.headers.authorization = action.payload;
    },
    setMarketingImage(state, action) {
      state.marketingImage = action.payload
    },
    resetState() {
      return {...initialState};
    }
  },
})

export const appDataSelector = (state: RootState) => state.appData;
export const appDataActions = appDataSlice.actions;