import React, { useEffect, useRef, useState } from 'react';
import './WlBookingsPage.scss';
import { AMICheckbox, AMIInput, AMISpinner, Icon } from '../../../../ui-components/UiComponents';
import { getFullWlBookingsData, getWlBookings } from '../../../../utilities/APIUtilities';
import { useAppSelector } from '../../../../app/hooks';
import { appDataSelector } from '../../../../features/appDataSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import WlBookingsTable from './wl-bookings-table/WlBookingsTable';
import { WlBookingHistoryItem } from './WlBookingsPage-Utils';
import dayjs from 'dayjs';
import WlExportModal from './wl-export-modal/WlExportModal';

const WlBookingsPage = () => {

  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);

  const filterRef = useRef<any>(null);

  const [isFetching, setIsFetching] = useState(true);
  const [unfilteredBookingHistory, setUnfilteredBookingHistory] = useState<WlBookingHistoryItem[]>([]);
  const [bookings, setBookings] = useState<any>([]);
  const [groupedBookings, setGroupedBookings] = useState<any>([]);
  const [groupByCompany, setGroupByCompany] = useState<boolean>(false);
  const [exportModalVisible, setExportModalVisible] = useState<boolean>(false);
  const [typedFilter, setTypedFilter] = useState<string>('');

  useEffect(() => {
    getAndSetBookings();
  }, [])

  useEffect(() => {
    if (unfilteredBookingHistory.length > 0) {
      onFilterChange(filterRef?.current?.value);
    }
  }, [unfilteredBookingHistory])

  const onFilterChange = (searchValue: string = '') => {
    let filterResult = unfilteredBookingHistory.filter((booking: WlBookingHistoryItem) => {
      return (
        dayjs(booking.shipmentDate).format('DD/MM/YYYY').includes(searchValue)
        || booking.companyName.toLowerCase().includes(searchValue.toLowerCase())
        || booking.customerShippingReference?.toLowerCase().includes(searchValue.toLowerCase())
      )
    })
    setBookings(filterResult);
    setGroupedBookings(groupBookings(filterResult));
    setTypedFilter(searchValue);
  }

  const getAndSetBookings = async() => {
    setIsFetching(true);
    const bookings = await getWlBookings(appData.apiConfig) as any;
    if (bookings) setUnfilteredBookingHistory(bookings.data)
    setIsFetching(false);
  }

  const groupBookings = (bookings: WlBookingHistoryItem[]) => {
    const grouped: any = {};
    for (const booking of bookings) {
      if (grouped[booking.companyName] === undefined) {
        grouped[booking.companyName] = [booking];
      } else {
        grouped[booking.companyName].push(booking);
      }
    }
    return grouped;
  }

  if (!isFetching && unfilteredBookingHistory.length === 0) return <h4>No bookings found.</h4>

  return (
    <>
      <h2>Bookings</h2>

      <div className="wl-bookings__top-row">
        <AMIInput
          refFn={filterRef}
          name="wl-filter"
          placeholder="Filter by Date or HAWB"
          onChange={(event) => onFilterChange(event.target.value)}
        />
        <div className="wl-bookings__top-row__actions-cont">
          <div className="wl-bookings__top-row__actions-cont__group-checkbox">
            <AMICheckbox
              text="Group by company"
              checked={groupByCompany}
              onChange={() => setGroupByCompany(!groupByCompany)}
            />
          </div>
          <div
            className="wl-bookings__top-row__actions-cont__download"
            onClick={() => setExportModalVisible(true)}
          >
            <Icon
              name="Download"
              style={{
                width: "20px",
                height: "20px"
              }}
            />
            <p>Export as CSV</p>
          </div>
        </div>
      </div>

      {isFetching && (
        <div className="wl-bookings__spinner">
          <AMISpinner />
          <p>Fetching bookings...</p>
        </div>
      )}

      {!isFetching && bookings.length > 0 && !groupByCompany && (
        <WlBookingsTable
          data={bookings}
          groupByCompany={groupByCompany}
          customer={customer}
          appData={appData}
        />
      )}

      {!isFetching && groupByCompany && (
        <>
          {Object.keys(groupedBookings).map((keyName: any) => {
            return (
              <div key={keyName}>
                <h2 style={{marginTop: "24px"}}>{keyName}</h2>
                <WlBookingsTable
                  data={groupedBookings[keyName]}
                  groupByCompany={groupByCompany}
                  customer={customer}
                  appData={appData}
                />
              </div>
            )
          })}
        </>
       )}

      {exportModalVisible && (
        <WlExportModal
          typedFilter={typedFilter}
          close={() => setExportModalVisible(false)}
        />
      )}
    </>
  )
}

export default WlBookingsPage;