import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { AMIDatePicker, AMIFormElement, AMIInput, AMIMultiButton } from '../../../../ui-components/UiComponents';
import { appDataActions, appDataSelector, OwnOrGenerate } from '../../../../features/appDataSlice';
import { bookingActions, bookingSelector, ShipmentType } from '../../../../features/bookingSlice';
import { customerDetailsSelector } from '../../../../features/customerSlice';
import { getCarrierName } from '../../../../utilities/HelperUtilities';
import { getRule, getTradeRoute, TradeRoute } from '../../../../utilities/RulesEngineUtilities';
import { getInvoiceNumber } from '../utilities';
import InvoiceCardGenerateInvoice from './invoice-card-generate-invoice/InvoiceCardGenerateInvoice';
import InvoiceCardOwnInvoice from './invoice-card-own-invoice/InvoiceCardOwnInvoice';
import './InvoiceCard.scss';

const InvoiceCard: React.FC<{
  errHandler: any;
  customRules: any;
}> = ({
  errHandler,
  customRules
}) => {

  const dispatch = useAppDispatch();
  const booking = useAppSelector(bookingSelector);
  const appData = useAppSelector(appDataSelector);
  const customer = useAppSelector(customerDetailsSelector);
  const invoiceData = appData.invoice;
  const showErrors = appData.showDetailsPageErrors;
  const tradeRoute = getTradeRoute(customer, booking);

  const onInvoiceDateChange = (event: any) => {
    dispatch(appDataActions.updateInvoiceDetail({
      field: 'date',
      value: event
    }))
  }

  const onInvoiceNumberChange = (event: any) => {
    dispatch(appDataActions.updateField({
      field: 'isInvoiceNumberOverwritten',
      value: true
    }))
    dispatch(appDataActions.updateInvoiceDetail({
      field: 'number',
      value: event.target.value
    }))
  }

  const invoiceNumber = getInvoiceNumber(booking, appData);

  const eTradeCanGenerate = () => {
    const rule = getRule(appData.customRules, 'eTrade');
    const carrier = getCarrierName(booking.selectedQuote.quoteId);
    if (rule) {
      if (carrier === 'dhl') {
        return rule.dhl.generate
      } else return false
    } else return false
  }

  return (
    <div className="invoice-card horizontal-card">

      <p className="horizontal-card__title">Invoice</p>

      {booking.shipmentType === ShipmentType.NON_DOCS
      && (
        <>
          <div className="invoice-details-row">
            <AMIFormElement
              label="Invoice Number"
              errorMessage={
                showErrors
                && !errHandler.invoice.number.criteria
                ? errHandler.invoice.number.message
                : ''
              }
            >
            <AMIInput
              name="invoice-number"
              placeholder="Required"
              type="text"
              size="large"
              value={invoiceNumber}
              onChange={onInvoiceNumberChange}
              />
            </AMIFormElement>

            <AMIFormElement
              label="Invoice Date"
              errorMessage={
                showErrors
                && !errHandler.invoice.date.criteria
                ? errHandler.invoice.date.message
                : ''
              }
            >
              <AMIDatePicker
                size="large"
                placeholder="Required"
                style={{
                  width: '100%'
                }}
                datePicked={appData.invoice.date}
                allowPastDates
                allowWeekends
                onChange={onInvoiceDateChange}
              />
            </AMIFormElement>
          </div>

          {getRule(appData.customRules, 'southAfricaExportersCode') && (
            <AMIFormElement
              label="Exporter Code"
              errorMessage={
                showErrors
                && !errHandler.shipperDetails.exporterCode.criteria
                ? errHandler.shipperDetails.exporterCode.message
                : ""
              }
            >
              <AMIInput
                name="exporter-code"
                placeholder={
                  getRule(appData.customRules, 'southAfricaExportersCode')
                  && appData.invoice.ownOrGenerate === OwnOrGenerate.GENERATE
                    ? "Required"
                    : ""}
                type="number"
                size="large"
                value={booking.shipperDetails.exportComplianceStatement.exporterCode}
                onChange={(event) => dispatch(bookingActions.setExporterCode(event.target.value))}
              />
              <p style={{marginTop: "4px"}}>Your Exporter Code must be displayed on your invoice or the shipment <strong>will not clear customs</strong>.</p>
            </AMIFormElement>
          )}

          {eTradeCanGenerate() &&
            <AMIMultiButton
              buttons={[
                {title: 'I have my own invoice', value: OwnOrGenerate.OWN_INVOICE},
                {title: 'Generate Invoice Online', value: OwnOrGenerate.GENERATE}
              ]}
              selected={{
                title: invoiceData.ownOrGenerate,
                value: invoiceData.ownOrGenerate
              }}
              style={{
                maxWidth: '484px',
                marginBottom: '12px'
              }}
              onClick={(event: any) => dispatch(appDataActions.updateInvoiceDetail({
                field: 'ownOrGenerate',
                value: event.value
              }))}
            />
          }
        </>
      )}


      {invoiceData.ownOrGenerate === OwnOrGenerate.OWN_INVOICE && (
        <InvoiceCardOwnInvoice
          invoiceData={invoiceData}
          booking={booking}
          showErrors={showErrors}
          errHandler={errHandler}
          customRules={customRules}
          appData={appData}
        />
      )}

      {invoiceData.ownOrGenerate === OwnOrGenerate.GENERATE && (
        <InvoiceCardGenerateInvoice
          invoiceData={invoiceData}
          booking={booking}
          showErrors={showErrors}
          errHandler={errHandler}
          customRules={customRules}
          appData={appData}
        />
      )}

      {customer.countryOfResidence.value === "GB"
        && tradeRoute === TradeRoute.IMPORT
        && (
          <AMIFormElement
            label="CPC Number"
            style={{width: '50%'}}
          >
            <AMIInput
              name="cpc-number"
              type="text"
              size="large"
              value={booking.customsDetails.invoiceDetails.cpc}
              onChange={(event) => dispatch(bookingActions.updateInvoiceDetailField({field: "cpc", value: event.target.value}))}
            />
          </AMIFormElement>
        )
      }
    </div>
  )
}

export default InvoiceCard;