import React, { useEffect, useState } from 'react';
import './TrackingTable.scss';
import { AMITable, Icon } from '../../../ui-components/UiComponents';
import { BookingHistoryItem } from '../../../utilities/APIUtilities';
import dayjs from 'dayjs';
import TrackingModal from './tracking-modal/TrackingModal';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { useAppSelector } from '../../../app/hooks';
import { appDataSelector } from '../../../features/appDataSlice';

const TrackingTable: React.FC<{data: BookingHistoryItem[]}> = ({data}) => {

  const [displayData, setDisplayData] = useState<BookingHistoryItem[]>([]);
  const [activeColumn, setActiveColumn] = useState('bookingDate');
  const [sortDirection, setSortDirection] = useState(3);
  const [chosenBooking, setChosenBooking] = useState<BookingHistoryItem>();

  const appData = useAppSelector(appDataSelector);
  const screenWidth = useWindowDimensions().width;
  const isWlDomainOrigin = appData.isWl;

  useEffect(() => {
    const sortedData = data.sort((a,b) => new Date(b.shipmentDate).getTime() - new Date(a.shipmentDate).getTime());
    setDisplayData(sortedData);
  }, [data])

  const resetSort = () => {
    const displayDataCopy = [...displayData];
    displayDataCopy.sort((a,b) => new Date(b.shipmentDate).getTime() - new Date(a.shipmentDate).getTime());
    setSortDirection(1);
    setDisplayData(displayDataCopy);
  }

  const sortByDate = () => {
    setActiveColumn('bookingDate');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => new Date(a.shipmentDate).getTime() - new Date(b.shipmentDate).getTime());
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => new Date(b.shipmentDate).getTime() - new Date(a.shipmentDate).getTime());
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByYourRef = () => {
    setActiveColumn('yourRef');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.customerShippingReference?.localeCompare(b.customerShippingReference));
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.customerShippingReference?.localeCompare(a.customerShippingReference));
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByShipmentID = () => {
    setActiveColumn('shipmentId');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.shipmentId.localeCompare(b.shipmentId));
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.shipmentId.localeCompare(a.shipmentId));
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByAmiRef = () => {
    setActiveColumn('amiReference');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.amiReference?.localeCompare(b.amiReference));
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.amiReference?.localeCompare(a.amiReference));
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByConsignee = () => {
    setActiveColumn('consignee');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.deliveryDetails.address.organisation.localeCompare(b.deliveryDetails.address.organisation));
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.deliveryDetails.address.organisation.localeCompare(a.deliveryDetails.address.organisation));
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }

  const sortByDestination = () => {
    setActiveColumn('destination');
    const displayDataCopy = [...displayData];
    if (sortDirection === 1) {
      displayDataCopy.sort((a,b) => a.deliveryDetails.address.city.localeCompare(b.deliveryDetails.address.city));
      setSortDirection(2);
    } else if (sortDirection === 2) {
      displayDataCopy.sort((a,b) => b.deliveryDetails.address.city.localeCompare(a.deliveryDetails.address.city));
      setSortDirection(3);
    } else {
      resetSort();
      return;
    }
    setDisplayData(displayDataCopy)
  }


  const downChevron = (
    <Icon
      name="ChevronDown"
      style={{
        width: '12px',
        paddingLeft: '2px',
        transform: 'translate(2px, 6px) scale(1.2)',
        fill: 'var(--primary)',
        position: 'absolute'
      }}
    />
  )

  const upChevron = (
    <Icon
      name="ChevronUp"
      style={{
        width: '12px',
        paddingLeft: '2px',
        fill: 'var(--primary)',
        transform: 'translate(2px, -4px) scale(1.2)',
        position: 'absolute'
      }}
    />
  )

  const ghostChevrons = (
    <div className="ghost-chevrons">
      <Icon
        name="ChevronUp"
        style={{
          width: '12px',
          paddingLeft: '2px',
          fill: 'var(--secondary)',
          transform: 'translate(2px, -4px) scale(1.2)',
          position: 'absolute',
        }}
      />
      <Icon
        name="ChevronDown"
        style={{
          width: '12px',
          paddingLeft: '2px',
          transform: 'translate(2px, 6px) scale(1.2)',
          fill: 'var(--secondary)',
          position: 'absolute',
        }}
      />
    </div>
  )

  const onBookingClick = (booking: BookingHistoryItem) => {
    setChosenBooking(booking)
  }

  const [clickTime, setClickTime] = useState(0);

  const onMouseDown = () => {
    setClickTime(Date.now());
  }

  const onMouseUp = (booking: any) => {
    if (Date.now() - clickTime < 400) onBookingClick(booking);
  }

  return (
    <>
      <AMITable className="booking-history-table">
        <thead>
          <tr className="booking-history-table__head-row">
            <th
              onClick={sortByDate}
              style={{paddingRight: '24px'}}
            >
              Booking Date
              {ghostChevrons}
              {activeColumn === 'bookingDate'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'bookingDate'
                && sortDirection === 2
                && upChevron
              }
            </th>

            <th onClick={sortByYourRef}>
              Your Ref
              {ghostChevrons}
              {activeColumn === 'yourRef'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'yourRef'
                && sortDirection === 2
                && upChevron
              }
            </th>

            {screenWidth > 1050 && <th onClick={sortByShipmentID}>
              Shipment ID
              {ghostChevrons}
              {activeColumn === 'shipmentId'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'shipmentId'
                && sortDirection === 2
                && upChevron
              }
            </th>}

            <th onClick={sortByAmiRef}>
              {isWlDomainOrigin ? 'ExpressFr8 Reference' : 'AMI Reference'}
              {ghostChevrons}
              {activeColumn === 'amiReference'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'amiReference'
                && sortDirection === 2
                && upChevron
              }
            </th>

            {screenWidth > 700 && <th onClick={sortByConsignee}>
              Consignee Company
              {ghostChevrons}
              {activeColumn === 'consignee'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'consignee'
                && sortDirection === 2
                && upChevron
              }
            </th>}

            {screenWidth > 600 && <th onClick={sortByDestination}>
              Destination
              {ghostChevrons}
              {activeColumn === 'destination'
                && sortDirection === 3
                && downChevron
              }
              {activeColumn === 'destination'
                && sortDirection === 2
                && upChevron
              }
            </th>}

          </tr>
        </thead>
        <tbody>
          {displayData.map((booking: BookingHistoryItem, index: number) => {
            return (
              <tr
                key={index}
                onMouseDown={() => onMouseDown()}
                onMouseUp={() => onMouseUp(booking)}
              >
                {screenWidth >= 640 && <td>{dayjs(booking.shipmentDate).format('DD/MM/YYYY')}</td>}
                {screenWidth < 640 && <td>{dayjs(booking.shipmentDate).format('DD/MM/YY')}</td>}

                <td>{booking.customerShippingReference}</td>
                {screenWidth > 1050 && <td>{booking.shipmentId}</td>}
                <td>{booking.amiReference}</td>
                {screenWidth > 700 && <td>{booking.deliveryDetails.address.organisation}</td>}
                {screenWidth > 600 && <td>{booking.deliveryDetails.address.city}, {booking.deliveryDetails.address.countryCode}</td>}
              </tr>
            )
          })}
        </tbody>
      </AMITable>

      {chosenBooking && (
        <TrackingModal
          booking={chosenBooking}
          closeModal={() => setChosenBooking(undefined)}
          appData={appData}
        />
      )}
    </>
  )
}

export default TrackingTable;