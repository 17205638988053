import axios from "axios";
import { CustomerDetails } from "../features/customerSlice";
import { usFaqs } from "../pages-ancillary/faq/utilities";
import {Booking, CustomDropIn} from "../features/bookingSlice";
import {formatCarrierName, getCarrierName} from "./HelperUtilities";
import dayjs from 'dayjs';

async function contentRequest(page: string, countryCode: string, customer: CustomerDetails, apiConfig: any, forChild?: boolean) {
  const isWlChild = apiConfig.headers['IdToken'];

  if (isWlChild) page = page + '-' + customer.parentPartyId;
  else if (forChild) page = page + "-" + customer.creditCheck.tmffPartyId;
  else page = page + '-NEBE';

  const requestBody = {
    "params": {
      "countryOfOrigin": countryCode,
      "page": page
    }
  };
  let results;
  const url = apiConfig.headers['IdToken']
    ? `${process.env.REACT_APP_NEBE_API_URL}/white-label/indirect/v1/content`
    : `${process.env.REACT_APP_NEBE_API_URL}/v2/content`
  await axios.post(
    url,
    requestBody,
    apiConfig
  ).then((response: any) => {
    results = response.data;
  }, (error) => {
    console.error('Content retriever error', error)
  });
  return results;
}

export async function retrieveContent (page: string, countryCode: any, customer: CustomerDetails, apiConfig: any, forChild?: boolean) {
  let content;
  if (countryCode !== null) {
    await contentRequest(page, countryCode, customer, apiConfig, forChild)
      .then((result: any) => {
        if (Object.keys(result).length !== 0) content = result.Item.content
      })
      .catch(error => console.error(error));
  } else if (!customer.customerCountryCode) {
    return usFaqs
  } else {
    await contentRequest(page, customer.customerCountryCode, customer, apiConfig, forChild)
      .then((result: any) => {
        content = result.Item.content
      })
      .catch(error => {console.error(error)});
  }
  return content;
}

export async function retrieveImage (customer: CustomerDetails, imageId: string) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "authorization": `Bearer ${customer.auth.accessToken}`
    },
    responseType: 'arraybuffer'
  } as any;

  return axios.get(
    `${process.env.REACT_APP_NEBE_API_URL}/v2/content/image?key=${imageId}`,
    config
  ).then((response: any) => {
    return response;
  }, (error) => {
    console.error('Content retriever error', error);
    return 404;
  });
}

export async function retrieveDDPContent(booking: Booking, customer: CustomerDetails, apiConfig: any) {
  const carrier = formatCarrierName(getCarrierName(booking.selectedQuote.quoteId));
  return retrieveContent(`details-DDP-${carrier}`, customer.customerCountryCode, customer, apiConfig)
      .then((result: any) => {return result})
      .catch(error => console.error(error))
}

export async function retrieveCourierInfoContent(booking: Booking, customer: CustomerDetails, apiConfig: any, quoteId?: string) {
  const carrier = getCarrierName(quoteId ? quoteId : booking.selectedQuote.quoteId);
  let pageRef = `details-courierInfo-${carrier}`;
  return retrieveContent(pageRef, customer.customerCountryCode, customer, apiConfig)
    .then((result: any) => {
      if (result) return result
      else return retrieveContent('details-courierInfo', customer.customerCountryCode, customer, apiConfig)
        .then((result: any) => {return result})
        .catch((error: any) => console.error(error))
    })
    .catch((error: any) => console.error(error))
}

const findContactNumber = (contactNumbers: any, customer: CustomerDetails) => {
  switch (customer.customerCountryCode) {
    case 'GB': return contactNumbers.GB;
    case 'AU': return contactNumbers.AU;
    case 'NZ': return contactNumbers.NZ;
    case 'US': return contactNumbers.US;
    case 'ZA': return contactNumbers.ZA;
  }
}
export async function retrieveContactNo(customer: CustomerDetails, apiConfig: any) {
  let contactNo;
  await retrieveContent('finish-contactNumbers', 'ALL', customer, apiConfig)
    .then((result: any) => contactNo = findContactNumber(result, customer).value)
    .catch((error: any) => console.error(error));
  return contactNo;
}

export interface Banner {
    id: string,
    title: string,
    content: string,
    config: {
      announcementType: 'warning' | 'info',
      displayFrom: string,
      displayUntil: string
    },
    page?: 'home' | 'quote'
}

const findBanners = (result: any, forChild?: boolean) => {
  let banners: Banner[] = [];

  for (const el in result) {
    const banner = result[el];
    const displayFrom = dayjs(banner.config.displayFrom);
    const displayUntil = dayjs(banner.config.displayUntil);
    const today = dayjs();

    if (forChild) banners.push(banner);
    else if (
      displayFrom.isValid()
      && displayUntil.isValid()
      && (
        (displayFrom.isBefore(today, 'day') || displayFrom.isSame(today, 'day'))
        && (displayUntil.isAfter(today, 'day')) || displayUntil.isSame(today, 'day'))
     ) {
        banners.push(banner);
      }
  }

  return banners;
}

export async function retrieveBanners(page: string, customer: CustomerDetails, apiConfig: any, forChild?: boolean) {
  let banners: undefined | Banner[] = [];
  await retrieveContent(page, customer.customerCountryCode, customer, apiConfig, forChild)
    .then((result: any) => {
      banners = findBanners(result, forChild)
})
    .catch((error: any) => console.error(error));
  return banners;
}

export async function retrieveAMIDropInLocations(booking: Booking, customer: CustomerDetails, apiConfig: any) {
  let locations: any[] = [];
  await retrieveContent('details-ami-drop-in-locations', booking.origin.value, customer, apiConfig)
    .then((results: any) => {
      if (results) {
        results.forEach((result: CustomDropIn) => {
          if (!result.carrier) {
            console.error("Drop-In Location missing carrier field.")
          } else {
            if (result.carrier === "AMI" || result.carrier === getCarrierName(booking.selectedQuote.quoteId)) {
              locations.push(result)
            }
          }
        });
      }
    })
    .catch((error: any) => console.error(error))
  return locations;
}
